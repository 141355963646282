import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import { changeActiveSidebarItem } from "../../redux/actions/navigationAction.js";
import { logoutUser } from "../../redux/actions/auth";
import Logo from "../Icons/SidebarIcons/logo.png";
import Stech from "../Icons/SidebarIcons/Stech.svg";
import { BsFileEarmarkTextFill } from "react-icons/bs";
import { AiOutlineBars } from "react-icons/ai";
import { IoInformationCircle, IoPersonAdd } from "react-icons/io5";
import {
  MdDashboard,
  MdGroup,
  MdLocationOn,
  MdOutlineCategory,
  MdTableChart,
} from "react-icons/md";
import { FaBuilding, FaNetworkWired } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { MdSchool, MdWork } from "react-icons/md";
import { CgSmartphone } from "react-icons/cg";
import { RiQrScan2Line, RiShieldCheckFill } from "react-icons/ri";
import jazzLogo from "../../assets/others/Jazz/Jazz.png";

import { MdUpload } from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";

class Sidebar extends React.Component {
  static propTypes = {
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    activeItem: "",
  };
  state = {
    showLogo: true,
    sideBarData: [
      {
        title: "DashBoard",
        isHeader: true,
        iconName: <MdDashboard size={20} />,
        link: "/dashboard",
        perms: "dashboard",
        index: "dashboard",
      },

     // Student
      {
        title: "Students",
        isHeader: true,
        iconName: <MdSchool size={20} />,
        link: "/student",
        index: "student",
        perms: "student_perms",
        subNav: [
          {
            header: `List Of Students`,
            link: "/students/list-of-Students",
            index: "student",
            iconName: <AiOutlineBars size={20} />,
          },
          {
            header: " Add Student",
            link: "/students/add-student",
            index: "student",
            iconName: <IoPersonAdd size={20} />,
          },
          {
            header: "Upload Image",
            link: "/students/import-images",
            index: "student",
            iconName: <MdUpload size={20} />,
          },
          // {
          //   header: "Settings",
          //   link: "/edge/settings",
          //   index: "student",
          //   iconName: <IoMdSettings size={20} />,
          // },
        ],
      },
      {
        title: "Students Reports",
        isHeader: true,
        iconName: <BsFileEarmarkTextFill size={20} />,
        link: "/attendance",
        index: "attendance",
        perms: "students_reports_perms",
        subNav: [
          {
            header: "Attendance Report",
            link: "/students-reports/attendance-report",
            index: "attendance",
            iconName: <RiShieldCheckFill size={20} />,
          },
          {
            header: "Detection Report",
            link: "/students-reports/detection-report",
            index: "attendance",
            iconName: <RiQrScan2Line size={20} />,
          },
        ],
      },
      {
        title: "Staff",
        isHeader: true,
        iconName: <MdWork size={20} />,
        link: "/staffs",
        index: "staffs",
        perms: "staff_perms",
        subNav: [
          {
            header: `List Of Staff`,
            link: "/staff/list-of-staff",
            index: "staffs",
            iconName: <AiOutlineBars size={20} />,
          },
          {
            header: " Add Staff Member",
            link: "/staff/add-staff-member",
            index: "staffs",
            iconName: <IoPersonAdd size={20} />,
          },
          {
            header: "Upload Image",
            link: "/staff/import-images",
            index: "staffs",
            iconName: <MdUpload size={20} />,
          },
          // {
          //   header: "Settings",
          //   link: "/edge/settings",
          //   index: "staffs",
          //   iconName: <IoMdSettings size={20} />,
          // },
        ],
      },
      {
        title: "Staff Reports",
        isHeader: true,
        iconName: <BsFileEarmarkTextFill size={20} />,
        link: "/report",
        index: "report",
        perms: "staff_reports_perms",

        subNav: [
          {
            header: "Attendance Report",
            link: "/staff-reports/attendance-report",
            index: "report",
            iconName: <RiShieldCheckFill size={20} />,
          },
          {
            header: "Detection Report",
            link: "/staff-reports/detection-report",
            index: "report",
            iconName: <RiQrScan2Line size={20} />,
          },
        ],
      },
      {
        title: "Devices",
        isHeader: true,
        iconName: <CgSmartphone size={20} />,
        perms: "devices_perms",
        link: "/devices",
        index: "device",
      },
      {
        title: "Lookups",
        isHeader: true,
        iconName: <MdTableChart size={20} />,
        link: "/lookup",
        index: "lookup",
        perms: "lookups_perms",
        subNav: [
          {
            header: "Branch",
            link: "/lookups/branch",
            index: "lookup",
            iconName: <FaBuilding size={18} />,
          },
          {
            header: "Department",
            link: "/lookups/department",
            index: "lookup",
            iconName: <FaNetworkWired size={18} />,
          },
          {
            header: "Section",
            link: "/lookups/section",
            index: "lookup",
            iconName: <MdOutlineCategory size={20} />,
          },
          {
            header: "Class",
            link: "/lookups/class",
            index: "lookup",
            iconName: <SiGoogleclassroom size={18} />,
          },
          {
            header: "Location",
            link: "/lookups/location",
            index: "lookup",
            iconName: <MdLocationOn size={20} />,
          },
        ],
      },
      {
        title: "Users",
        isHeader: true,
        perms: "users_perms",
        iconName: <MdGroup size={20} />,
        link: "/users",
        index: "users",
      },
      {
        title: "About",
        isHeader: true,
        iconName: <IoInformationCircle size={20} />,
        link: "/about",
        index: "about",
      },
    ],
    studentSidebarData: [
      {
        title: "DashBoard",
        isHeader: true,
        iconName: <MdDashboard size={20} />,
        link: "/dashboard",
        perms: "dashboard",
        index: "dashboard",
      },

      //Student
      {
        title: "Students",
        isHeader: true,
        iconName: <MdSchool size={20} />,
        link: "/student",
        index: "student",
        perms: "student_perms",
        subNav: [
          {
            header: `List Of Students`,
            link: "/students/list-of-Students",
            index: "student",
            iconName: <AiOutlineBars size={20} />,
          },
          {
            header: " Add Student",
            link: "/students/add-student",
            index: "student",
            iconName: <IoPersonAdd size={20} />,
          },
          {
            header: "Upload Image",
            link: "/students/import-images",
            index: "student",
            iconName: <MdUpload size={20} />,
          },
          // {
          //   header: "Settings",
          //   link: "/edge/settings",
          //   index: "student",
          //   iconName: <IoMdSettings size={20} />,
          // },
        ],
      },
      {
        title: "Students Reports",
        isHeader: true,
        iconName: <BsFileEarmarkTextFill size={20} />,
        link: "/attendance",
        index: "attendance",
        perms: "students_reports_perms",
        subNav: [
          {
            header: "Attendance Report",
            link: "/students-reports/attendance-report",
            index: "attendance",
            iconName: <RiShieldCheckFill size={20} />,
          },
          {
            header: "Detection Report",
            link: "/students-reports/detection-report",
            index: "attendance",
            iconName: <RiQrScan2Line size={20} />,
          },
        ],
      },
      {
        title: "Devices",
        isHeader: true,
        iconName: <CgSmartphone size={20} />,
        perms: "devices_perms",
        link: "/devices",
        index: "device",
      },
      {
        title: "Lookups",
        isHeader: true,
        iconName: <MdTableChart size={20} />,
        link: "/lookup",
        index: "lookup",
        perms: "lookups_perms",
        subNav: [
          {
            header: "Branch",
            link: "/lookups/branch",
            index: "lookup",
            iconName: <FaBuilding size={18} />,
          },
          {
            header: "Department",
            link: "/lookups/department",
            index: "lookup",
            iconName: <FaNetworkWired size={18} />,
          },
          {
            header: "Section",
            link: "/lookups/section",
            index: "lookup",
            iconName: <MdOutlineCategory size={20} />,
          },
          {
            header: "Class",
            link: "/lookups/class",
            index: "lookup",
            iconName: <SiGoogleclassroom size={18} />,
          },
          {
            header: "Location",
            link: "/lookups/location",
            index: "lookup",
            iconName: <MdLocationOn size={20} />,
          },
        ],
      },
      {
        title: "Users",
        isHeader: true,
        perms: "users_perms",
        iconName: <MdGroup size={20} />,
        link: "/users",
        index: "users",
      },
      {
        title: "About",
        isHeader: true,
        iconName: <IoInformationCircle size={20} />,
        link: "/about",
        index: "about",
      },
    ],
    staffSidebarData: [
      {
        title: "DashBoard",
        isHeader: true,
        iconName: <MdDashboard size={20} />,
        link: "/dashboard",
        perms: "dashboard",
        index: "dashboard",
      },

      {
        title: "Staff",
        isHeader: true,
        iconName: <MdWork size={20} />,
        link: "/staffs",
        index: "staffs",
        perms: "staff_perms",
        subNav: [
          {
            header: `List Of Staff`,
            link: "/staff/list-of-staff",
            index: "staffs",
            iconName: <AiOutlineBars size={20} />,
          },
          {
            header: " Add Staff Member",
            link: "/staff/add-staff-member",
            index: "staffs",
            iconName: <IoPersonAdd size={20} />,
          },
          {
            header: "Upload Image",
            link: "/staff/import-images",
            index: "staffs",
            iconName: <MdUpload size={20} />,
          },
          // {
          //   header: "Settings",
          //   link: "/edge/settings",
          //   index: "staffs",
          //   iconName: <IoMdSettings size={20} />,
          // },
        ],
      },
      {
        title: "Staff Reports",
        isHeader: true,
        iconName: <BsFileEarmarkTextFill size={20} />,
        link: "/report",
        index: "report",
        perms: "staff_reports_perms",

        subNav: [
          {
            header: "Attendance Report",
            link: "/staff-reports/attendance-report",
            index: "report",
            iconName: <RiShieldCheckFill size={20} />,
          },
          {
            header: "Detection Report",
            link: "/staff-reports/detection-report",
            index: "report",
            iconName: <RiQrScan2Line size={20} />,
          },
        ],
      },
      {
        title: "Devices",
        isHeader: true,
        iconName: <CgSmartphone size={20} />,
        perms: "devices_perms",
        link: "/devices",
        index: "device",
      },
      {
        title: "Lookups",
        isHeader: true,
        iconName: <MdTableChart size={20} />,
        link: "/lookup",
        index: "lookup",
        perms: "lookups_perms",
        subNav: [
          {
            header: "Branch",
            link: "/lookups/branch",
            index: "lookup",
            iconName: <FaBuilding size={18} />,
          },
          {
            header: "Department",
            link: "/lookups/department",
            index: "lookup",
            iconName: <FaNetworkWired size={18} />,
          },
          {
            header: "Location",
            link: "/lookups/location",
            index: "lookup",
            iconName: <MdLocationOn size={20} />,
          },
        ],
      },
      {
        title: "Users",
        isHeader: true,
        perms: "users_perms",
        iconName: <MdGroup size={20} />,
        link: "/users",
        index: "users",
      },
      {
        title: "About",
        isHeader: true,
        iconName: <IoInformationCircle size={20} />,
        link: "/about",
        index: "about",
      },
    ],
  };
  constructor(props) {
    super(props);

    this.doLogout = this.doLogout.bind(this);
  }

  componentDidMount() {
    localStorage.setItem("sidebar-collapsed", false);
    this.element.addEventListener(
      "transitionend",
      () => {
        if (this.props.sidebarOpened) {
          this.element.classList.add(s.sidebarOpen);
        }
      },
      false
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.sidebarOpened !== prevProps.sidebarOpened) {
      if (this.props.sidebarOpened) {
        this.element.style.height = `276px`;
      } else {
        this.element.classList.remove(s.sidebarOpen);
        setTimeout(() => {
          this.element.style.height = "";
        }, 0);
      }
    }
  }

  doLogout(id) {
    this.props.dispatch(logoutUser());
  }

  render() {
    const { isExpanded } = this.props;
    const { sideBarData, staffSidebarData, studentSidebarData } = this.state;
    let renderData = [];
    const showLogo = process.env.REACT_APP_JAZZ_LOGO;
    const isStudent = process.env.REACT_APP_IS_STUDENT;
    const isStaff = process.env.REACT_APP_IS_STAFF;
    const UserInfo = localStorage.getItem("user");
    if (isStudent === "true") {
      renderData = studentSidebarData;
    }
    if (isStaff === "true") {
      renderData = staffSidebarData;
    }
    if (isStudent === "true" && isStaff === "true") {
      renderData = sideBarData;
    }
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    if(!permissions){
      localStorage.clear();
       window.location.reload()
    }
    const {
      staff_perms,
      users_perms,
      devices_perms,
      lookups_perms,
      student_perms,
      staff_reports_perms,
      students_reports_perms,
    } = permissions;
    return (
      <>
        <nav
          className={isExpanded ? s.minWidth : s.root}
          ref={(nav) => {
            this.element = nav;
          }}
        >
          <header className={s.logo}>
            {!isExpanded && (
              <img
                className="float-right"
                style={{ maxHeight: 100, maxWidth:"100%" }}
                src={Logo}
              />
            )}
            {/* <span className={s.title}>Simplicity.Ai</span> */}
          </header>
          <ul className={s.nav}>
            {renderData.map((item) => {
              if (
                (item.perms == "student_perms" && student_perms) ||
                (item.perms == "students_reports_perms" &&
                  students_reports_perms) ||
                (item.perms == "staff_perms" && staff_perms) ||
                (item.perms == "staff_reports_perms" && staff_reports_perms) ||
                (item.perms == "lookups_perms" && lookups_perms) ||
                (item.perms == "devices_perms" && devices_perms) ||
                (item.perms == "users_perms" && users_perms) ||
                UserInfo === "superadmin" ||
                item.perms == "dashboard"
              ) {
                return (
                  <LinksGroup
                    onActiveSidebarItemChange={(activeItem) =>
                      this.props.dispatch(changeActiveSidebarItem(activeItem))
                    }
                    activeItem={this.props.activeItem}
                    header={!isExpanded ? item.title : null}
                    isHeader
                    iconName={item.iconName}
                    link={item.link}
                    index={item.index}
                    childrenLinks={
                      item.subNav &&
                      item.subNav.map((item) => {
                        const newProjects = {
                          ...item,
                          header: !isExpanded ? item.header : null,
                        };
                        return newProjects;
                      })
                    }
                  />
                );
              }
            })}
          </ul>
        </nav>
        {!isExpanded ? (
          <footer
            className={
              showLogo === "true" ? `${s.footerLogoImage}` : `${s.footerLogo}`
            }
          >
            <div className="float-left">
              {showLogo === "true" ? (
                <img className={s.jazzLogo} src={jazzLogo} />
              ) : null}
            </div>
            <div>
              <h6 className={s.logoText}>
                Powered by
                {showLogo === "false" ? (
                  <img className={s.stechlogo} src={Stech} />
                ) : null}
              </h6>
              {showLogo === "true" ? (
                <img className={s.stechlogo} src={Stech} />
              ) : null}
            </div>
          </footer>
        ) : null}
      </>
    );
  }
}
function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
    isExpanded: store.navigation.isExpanded,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
